﻿/*
  Simon Search
  By: Alex Sperellis

  Description:
  Global search for website in the header
*/

module.exports = (function (loadAsOpen, host = false) {
  // variables
  const $vipNavContainer = $('.vip-nav-container');
  const $vipDropdown = $('#vipDropdown');
  const $spoNavContainer = $('.spo-nav-container');
  const $spoDropdown = $('#spoDropdown');
  const $searchResults = $('.search-results');
  const $searchLinks = $('.search-links');
  const $searchInput = $('.search-input');
  const $searchToggle = $('.search-toggle');
  const $searchTray = $('.search');
  const $findNearbyBtn = $('.js-find-nearby');
  var onYourLocation = document.location.href.indexOf('your-location') > -1;
  var hasLocation = document.querySelector('.your-location-results');
  let resultTypes = [];
  let results = [];

  // change inline svg
  const changeSvg = function changeSvg(origEl, newId) {
    $(origEl + ' use').prop('href').baseVal = newId;
  };

  // geolocation
  const findNearbyCenters = function () {
    const successCallback = function (pos) {
      $('#longitude').val(pos.coords.longitude);
      $('#latitude').val(pos.coords.latitude);
      if(pos.coords && pos.coords.longitude && pos.coords.latitude) {
        $("#FindNearbyMallsForm").trigger('submit');
      }
    }

    const errorCallback = function (e) {
      $('.js-find-nearby').addClass('hidden-xs-up');
      alert('Sorry. Your web browser does not support the sharing of your current location. Therefore the "Use Your Location" feature will not work.');
      window.location = (host || '') + '/search';
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback, { timeout: 10000 });
    }
  }

  // find nearby on ready - only sets a click event unless you are on /your-location
  $(document).ready(function () {
    $findNearbyBtn.on("click", function (e) {
      e.preventDefault();

      if(onYourLocation && !hasLocation) {
        findNearbyCenters();
        return false;
      } else if(!onYourLocation) {
        window.location = (host || "https://" + window.location.hostname) + "/search/your-location";
      }
      
      return false;
    });
  });

  $(window).on('load', function () {
    if(onYourLocation && !hasLocation) {
      findNearbyCenters();
    }
  });

  // function to close an element
  const close = function (el) {
    el.addClass('collapsed').removeClass('open');
    $('body').removeClass('search-open');
  };

  // function to open an element
  const open = function (el) {
    el.addClass('open').removeClass('collapsed');
    if (el.hasClass('search-results') || el.hasClass('search-links')) {
      $('body').addClass('search-open');
    } else {
      $('body').removeClass('search-open');
    }

    if(window.innerWidth < 992) {
      changeSvg('.search-toggle.hidden-lg-up .icon-search', '#icon-close');
    }
  };

  // if isOpen is set to true - load with search tray open
  if (loadAsOpen) {
    open($searchTray);
    open($searchToggle);

    if(window.innerWidth < 992) {
      changeSvg('.search-toggle.hidden-lg-up .icon-search', '#icon-close');
    }
  }

  // on click of search icon - toggle search toggle class and search tray
  $searchToggle.on("click", function (e) {
    e.preventDefault();

    // clear the search input for a fresh search
    $searchInput.val("");

    if ($(this).hasClass('open')) {
      close($searchTray);
      close($searchToggle);
      close($searchResults);
      close($searchLinks);

      if(window.innerWidth < 992 && $(this).hasClass('hidden-lg-up')) {
        changeSvg('.icon-search', '#icon-search');
      }
    } else {
      // close mobile nav
      document.querySelector('body').classList.remove('sidepanel-active');
      if(document.querySelector('.navbar-toggle')) {
        document.querySelector('.navbar-toggle').classList.remove('open');
        document.querySelector('.navbar-toggle').classList.add('collapsed');
        }
      if(document.querySelector('.navbar-sidepanel')) {
        document.querySelector('.navbar-sidepanel').classList.remove('open');
      }
      if(document.querySelector('.navbar-sidepanel-secondary')) {
        document.querySelector('.navbar-sidepanel-secondary').classList.remove('open');
      }

      close($vipNavContainer);
      close($vipDropdown);
      close($spoNavContainer);
      close($spoDropdown);
      open($searchTray);
      open($searchToggle);
      $searchInput.focus();

      if(window.innerWidth < 992 && $(this).hasClass('hidden-lg-up')) {
        changeSvg('.icon-search', '#icon-close');
      }
    }
  });

  // on exit of search input by click or tab out - close search results/quick links tray
  // on keyup if value of search input is empty show quick links
  // on focus of search input show quick links
  $searchInput.on('blur', function () {
    setTimeout(function () {
      close($searchResults);
      close($searchLinks);
      $searchTray.removeClass('focused');
    }, 500);
  }).on('keyup', function () {
      close($searchResults);
      open($searchLinks);
    }
  ).on('focus', function () {
    close($searchResults);
    open($searchLinks);
    if (window.innerWidth <= 768) {
      $searchTray.addClass('focused');
    }
  });

  // encode the search query string
  const simonEncodeUrl = function (queryString) {
    const getType = {};
    if (queryString && getType.toString.call(queryString) !== '[object Function]') {
      queryString = queryString.toLowerCase()
                        .replace(new RegExp(/\u00ae/g), '')  // Registered Sign: '®' => ''
                        .replace(new RegExp(/\u2122/g), '')  // Trade Mark Sign: '™' => ''
                        .replace(new RegExp(/\%/g), '%25')   // Percent: '%' => '%25'
                        .replace(new RegExp(/\?/g), '%3F')   // Question Mark: '?' => '%3F'
                        .replace(new RegExp(/^[.]/), '%25.') // Period: '.' => "%." => "%25."
                        .replace(new RegExp(/\+/g), '%252B') // Plus: '+' => "%+" => '%252B'
                        .replace(new RegExp(/ /g), '+')      // Space: ' ' => '+'
                        // "Reserved  characters"
                        .replace(new RegExp(/\"/g), '%22')   // Dollar Sign: '$' => '%24'
                        .replace(new RegExp(/\&/g), '%26')   // Ampersand: "&" => '%26'
                        .replace(new RegExp(/\//g), '%2F')   // Forward slash/Virgule: '/' => '%2F'
                        .replace(new RegExp(/\:/g), '%3A')   // Colon: ':' => '%3A'
                        .replace(new RegExp(/\;/g), '%3B')   // Semi-colon: ';' => '%3B'
                        .replace(new RegExp(/\=/g), '%3D')   // Equals: '=' => '%3D'
                        //.replace(new RegExp(/\?/g), '%3F')   // Question Mark: '?' => '%3F'
                        .replace(new RegExp(/\@/g), '%40')   // At symbol: '@' => '%40'
                        // "Unsafe characters"
                        .replace(new RegExp(/\"/g), '%22')   // Double Quotation Mark: '"' => '%22'
                        .replace(new RegExp(/\'/g), '%27')   // Single Quotation Mark: "'" => '%27'
                        .replace(new RegExp(/\</g), '%3C')   // Less Than symbol: '<' => '%3C'
                        .replace(new RegExp(/\>/g), '%3E')   // Greater Than symbol: '>' => '%3E'
                        .replace(new RegExp(/\{/g), '%7B')   // Left Curly Brace: '{' => '%7B'
                        .replace(new RegExp(/\}/g), '%7D')   // Right Curly Brace: '}' => '%7D'
                        .replace(new RegExp(/\|/g), '%7C')   // Vertical Bar/Pipe: '|' => '%7C'
                        .replace(new RegExp(/\`/g), '%60')   // Grave Accent: '`' => '%60'
                        .replace(new RegExp(/\^/g), '%5E')   // Caret: '^' => '%5E'
                        .replace(new RegExp(/\^/g), '%5C')   // Backslash: '\' => '%5C'
                        .replace(new RegExp(/\[/g), '%5B')   // Left Square Bracket: '[' => '%5B'
                        .replace(new RegExp(/\[/g), '%5D')   // Right Square Bracket: ']' => '%5D'
                        .replace(new RegExp(/\~/g), '%7E')   // Tilde: '~' => '%7E'
                        .replace(new RegExp(/\#/g), '%23')   // Pound: '#' => '%23'
      ;
      // see http://www.blooberry.com/indexdot/html/topics/urlencoding.htm
      return queryString;
    }
    return false;
  };

  // gets the type of each autocomplete result
  const getResultType = function (key) {
    for (let i = 0; i < resultTypes.length; i++) {
      if (resultTypes[i][0] === key) {
        return resultTypes[i][1];
      }
    }
  };

  // if search query is of type Mall go to mall homepage, else go to the search results page
const getSearchResults = function (searchQuery) {
    //Cleanup the search box
    var trimVal = $searchInput.val().trim();
    //Check that there was even whitespace before updating the value and removing the whitespace.(eg. dont attempt to update if no whitespace)
    if ($searchInput.val() !== trimVal) {
        $searchInput.val(trimVal);
    }
    searchQuery = simonEncodeUrl(searchQuery.trim());

    switch (getResultType(searchQuery)) {
      case "Mall":
        window.location = (host || '') + "/mall/" + searchQuery;
        break;
      default:
        window.location = (host || '') + "/search/" + searchQuery;
        break;
    }
  };

  // on enter keypress - execute search
  $searchInput.on('keypress', function (e) {
    const code = (e.keyCode ? e.keyCode : e.which);
    if (code == 13 && $(this).val().length >= 2) {
        e.preventDefault();
        getSearchResults($(this).val().trim());
        return false;
    }
  });

  const customItemRenderer = function (ul, item) {
    return $('<li>')
      .attr('data-value', item.value.replace(/[™®©]/g, '<sup>$&</sup>'))
      .append(item.label.replace(/[™®©]/g, '<sup>$&</sup>'))
      .appendTo(ul);
  };

  // jqeury search autocomplete
  if($searchInput.length > 0) { 
    $searchInput.autocomplete({
    source: function (request, response) {
      $.ajax({
        url: window.apiUrl + '/search/GetMainSearchAutoCompleteWithType',
        dataType: 'json',
        data: {
          q: request.term,
          max: 50,
          hasWebsiteOnly: false,
          validPreferredMallsOnly: false,
          returnType: 'All',
          key: '40A6F8C3-3678-410D-86A5-BAEE2804C8F2'
        },
        success: function (data) {
          let firstkey;
          response($.map(data, function (item) {
            results = data;
            firstkey = item.Item1;
            if (firstkey != '')
              return { label: firstkey, value: firstkey };
          }));
          close($searchLinks);
          open($searchResults);
        },
        complete: function (data) {
          let firstkey;
          let firstval;
          let count = 0;
          if (results.length > 0) {
            $.each(results, function (key, value) {
              firstkey = value.Item1;
              firstval = value.Item2;
              resultTypes.push(new Array(firstkey, firstval));
              count++;
            });
          }
        }
      });
    },
    delay: 500,
    minLength: 2,
    appendTo: '.results-list',
    mustMatch: true,
    select: function onSelect(event, ui) {
      this.value = ui.item.label;
      getSearchResults(this.value);
    },
    selectFirst: false,
    focus: function onFocus(event, ui) {
      this.value = ui.item.label;
      event.preventDefault();
    }
  }).data("ui-autocomplete")._renderItem = customItemRenderer;
  }

  return {
    openSearchBox: open
  }
});