﻿module.exports = (function () {
    if (document.getElementById("spoDropdown")) {
        document.getElementById("spoDropdown").addEventListener("click", function (e) {
            if (document.getElementById("vipDropdown")) document.getElementById("vipDropdown").classList.remove("open");
            //const vipMenuContainer = document.getElementsByClassName("vip-nav-container");
            //vipMenuContainer[0].classList.remove("open");

            const vipMenuContainers = document.getElementsByClassName('vip-nav-container');
            Array.prototype.forEach.call(vipMenuContainers, function (el) {
                el.classList.remove("open");
            });

            document.body.classList.remove('search-open');

            closeByClass("search");
            closeByClass("search-toggle");
            closeByClass("search-links");
            closeByClass("search-results");

            const spoMenuContainer = document.getElementsByClassName("spo-nav-container");
            spoMenuContainer[0].classList.toggle("open");
            document.getElementById("spoDropdown").classList.toggle("open");
        });
    }

    if (document.getElementById("spoClose")) {
        const spoMegaMenu = document.querySelector('.spo-nav-container');
        document.getElementById("spoClose").addEventListener("click", function (e) {
            spoMegaMenu.classList.toggle("open");
            document.getElementById("spoDropdown").classList.toggle("open");
        });

        document.addEventListener('click', function handleClickOutsideBox(event) {
            if (!spoMegaMenu.contains(event.target) && event.target.id != 'spoDropdown') {
                spoMegaMenu.classList.remove('open');
                document.getElementById('spoDropdown').classList.remove('open');
            }
        });
    }

    if (document.getElementById("vipDropdown")) {
        document.getElementById("vipDropdown").addEventListener("click", function (e) {
            if (document.getElementById("spoDropdown")) {
                document.getElementById("spoDropdown").classList.remove("open");
            }
            const spoMenuContainer = document.getElementsByClassName("spo-nav-container");
            if (spoMenuContainer[0]) {
                spoMenuContainer[0].classList.remove("open");
            }
            document.body.classList.remove('search-open');

            closeByClass("search");
            closeByClass("search-toggle");
            closeByClass("search-links");
            closeByClass("search-results");

            const vipMenuContainer = document.getElementsByClassName("vip-nav-container");
            vipMenuContainer[0].classList.toggle("open");
            document.getElementById("vipDropdown").classList.toggle("open");
        });
    }

    if (document.getElementById("vipClose")) {
        document.getElementById("vipClose").addEventListener("click", function (e) {
            const vipMenuContainer = document.getElementsByClassName("vip-nav-container");
            Array.prototype.forEach.call(vipMenuContainer, function (el) {
                el.classList.remove("open");
            });
            document.getElementById("vipDropdown").classList.remove("open");
        });
    }
    if (document.getElementById("vipCloseLoggedIn")) {
        document.getElementById("vipCloseLoggedIn").addEventListener("click", function (e) {
            const vipMenuContainer = document.getElementsByClassName("vip-nav-container");
            Array.prototype.forEach.call(vipMenuContainer, function (el) {
                el.classList.remove("open");
            });
            document.getElementById("vipDropdown").classList.remove("open");
        });
    }

    function closeByClass(c) {
        const className = document.getElementsByClassName(c);
        if (className) {
            className[0].classList.remove("open");
            className[0].classList.add("collapsed");
        }
    }
    
});

