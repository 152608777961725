﻿/*
  app.js
  Global Script for project included on every page.
*/

// common libraries used on enough shared pages
require('../../ga/scripts/partials/libs');
// mobile navigation handler
require('../../ga/scripts/partials/mobilenav')();
// site dropdown handler. declared globally for header dropdown in PO pages
require('../../ga/scripts/partials/dropdowns')();
// floating labels handler for all forms pages
const floatingLabels = require('../../ga/scripts/partials/floatinglabels');
const appendToSpoLinks = require('../../ga/scripts/partials/linkAppender');
// SPO Menu
require('../../ga/scripts/partials/spo');

// spo dropdown - new
require('../../ga/scripts/partials/spodropdown')();


// site search handler. takes one param: true or false to control if auto open or not
require('../../ga/scripts/partials/simonsearch')(false);

// Captcha for login dropdown
const runCaptcha = require('../../ga/scripts/partials/recaptchasubmit');

const sidenav = require('../../ga/scripts/partials/sidenav');

(function () {
  const logoutBtn = document.getElementById('ddLogout');
  if(logoutBtn) {
    logoutBtn.addEventListener('click', () => {
      window.sessionStorage.removeItem('vipUser');
      location.href = '/vip/logout';
    });
  }

  const logoutBtnMenu = document.getElementById('logoutbtn');
  if(logoutBtnMenu) {
    logoutBtnMenu.addEventListener('click', () => {
      window.sessionStorage.removeItem('vipUser');
      location.href = '/vip/logout';
    });
  }

  const loginDropdown = document.getElementById('loginDropdown');
  if(loginDropdown) {
    runCaptcha('loginDropdown', 'vip_loginDropdown');
  }

  // Handles sideNavBurger
  if(document.getElementById('sideNavBurger')) {
    document.getElementById('sideNavBurger').addEventListener('click', sidenav.open);
  }
  if(document.getElementById('sideNavBurger')) {
    document.getElementById('globalSidenavClose').addEventListener('click', sidenav.close);
    document.addEventListener('click', e => {
      if(document.getElementById('globalSidenav') && !document.getElementById('globalSidenav').contains(e.target) && !document.getElementById('sideNavBurger').contains(e.target)) {
        sidenav.close();
      }
    });
  }

  // doc ready
  $(document).ready(function () {
  /* Footer VIP Insider Signup Form */

    document.getElementById('footerSignupButton').addEventListener('click', function (e) {
      e.preventDefault();
      let emailAddress;
      const separator = '?';
      if(document.getElementById('emailAddressFooter').value === '') {
        emailAddress = '';
      } else {
        emailAddress = 'email=' + encodeURIComponent(document.getElementById('emailAddressFooter').value);
      }

      if(typeof ga !== 'undefined') {
        if(window.location.href.indexOf('premiumoutlets') >= 0) {
          ga('send', 'event', {
            eventCategory: 'VIP Email Footer',
            eventAction: 'User Submission',
            eventLabel: window.location.href
          });
        } else {
          ga('send', 'event', {
            eventCategory: 'Mall Insider Email Footer',
            eventAction: 'User Submission',
            eventLabel: window.location.href
          });
        }
      }

      const vipUser = JSON.parse(window.sessionStorage.getItem('vipUser'));


      if(window.location.href.indexOf('premiumoutlets') >= 0) {
        if(vipUser) {
          // console.log("User is present");
          window.location.assign('/vip/centers');
        } else {
          // console.log("User not found.");
          window.location.assign('/vip/register' + separator + emailAddress + '&source=footer');
        }
      } else {
        // console.log("Mall Insider...");
        window.location.assign('/mall-insider' + separator + emailAddress + '&source=footer');
      }
    });

    // if there are floating labels init floating labels
    if($('label.floating').length > 0) {
      floatingLabels();
    }

    appendToSpoLinks();

    const isAndroid = /Android/i.test(navigator.userAgent);

    if(isAndroid) {
      $.smartbanner({
        title: 'SIMON APP',
        author: 'Simon Property Group',
        price: 'FREE',
        appStoreLanguage: 'us',
        inAppStore: 'On the App Store',
        inGooglePlay: 'In Google Play',
        icon: 'https://assets.simon.com/SimonWebAssets_Images/favicons/android-chrome-48x48.png',
        iconGloss: null,
        button: 'DOWNLOAD',
        scale: 'auto',
        speedIn: 300,
        speedOut: 400,
        daysHidden: 15,
        daysReminder: 30,
        force: 'android',
        layer: true
      });
    }

    // check if there is geolocation capabilities in the browser
    // if not, hide find malls by location
    if(!('geolocation' in navigator)) {
      $('.js-find-nearby').addClass('hidden-xs-up');
    }

    // vip login dropdown
    // if there is a dropdown and a cookie is available with user data
    // const vipUser = JSON.parse(window.sessionStorage.getItem('vipUser'));

    if($('.vip-dropdown').length > 0 && vipUser) {
      // show the logged in state
      $('.logged-in').removeClass('hidden');
      // remove the not logged in state
      $('.no-login').remove();
      // add username from cookie obj
      $('.userName').append(`${vipUser.FirstName} ${vipUser.LastName}`);
      // add preferred center from cookie obj
      if(vipUser.CenterIds) $('.centerName').append(vipUser.CenterIds[0].OutletName);
      document.querySelector('.centerName').innerHTML = document.querySelector('.centerName').innerHTML.replace(/[Â]/g, '').replace(/[®]/g, '<sup>&reg;</sup>');

      if(vipUser.opted_in_email) {
        $('.opted-out-email').addClass('hidden-xs-up');
        $('.opted-in-email').removeClass('hidden-xs-up');
      } else {
        // user does not have access to VIP account features, prompt resubscribe
        $('.opted-in-email').addClass('hidden-xs-up');
        $('.opted-out-email').removeClass('hidden-xs-up');
      }
    } else {
      // else the user is not logged in so hide logged in state
      $('.logged-in').addClass('hidden');
    }
  });

  // if there are lazy loaded images: unhide img and run lazyload function
  if($('img.img-lazy').length > 0) {
    $('img.img-lazy').removeClass('hidden-xs-up').lazyload({
      effect: 'fadeIn',
      failure_limit: 6
    });
  }
})();
